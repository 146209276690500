<template>
    <p class="text-center">
        <b-spinner variant="aspena-orange" />
        <br/>
        Načítání..
    </p>
</template>

<script>
export default {
    name: 'Loader'
}
</script>
