import { ApiModuleBase } from '../ApiModuleBase.js'

export class Enums extends ApiModuleBase {
    static get namespace () {
        return 'enums'
    }

    static get urlNamespace () {
        return 'enums'
    }

    async getEnums (lang) {
        const response = await this.doGetRequest(`/${this.ns}/all`)
        const enums = {}
        for (const enumEntry of response.data) {
            enums[enumEntry.id] = enumEntry.items.map(item => ({
                value: item.value,
                text: item.text,
                hint: item.hint
            }))
        }
        return enums
    }
}
