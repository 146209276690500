import ComponentError from '../Components/ComponentError.vue'
import ComponentLoader from '../Components/ComponentLoader.vue'
import LoaderOverlay from '../Components/LoaderOverlay.vue'
import ListGroup from '../Components/ListGroup.vue'
import Loader from '../Components/Loader.vue'
import AspenaBtn from '../Components/AspenaBtn.vue'

export const GlobalComponentsPlugin = {
    install (Vue) {
        Vue.component('component-loader', ComponentLoader)
        Vue.component('component-error', ComponentError)
        Vue.component('loader-overlay', LoaderOverlay)
        Vue.component('list-group', ListGroup)
        Vue.component('loader', Loader)
        Vue.component('aspena-btn', AspenaBtn)
    }
}
