<template>
    <div class="d-inline-block" v-b-tooltip.hover.bottom="hint || undefined">
        <b-btn class="btn-aspena" v-bind="$props" @click="onClick">
            <span class="btn-inner-text">
                <slot></slot>
            </span>
        </b-btn>
    </div>
</template>

<script>
export default {
    name: 'AspenaBtn',
    props: [
        'active',
        'active-class',
        'append',
        'block',
        'disabled',
        'exact',
        'exact-active-class',
        'href',
        'no-prefetch',
        'pill',
        'prefetch',
        'pressed',
        'rel',
        'replace',
        'router-component-name',
        'size',
        'squared',
        'tag',
        'target',
        'to',
        'type',
        'variant',
        'hint'
    ],
    methods: {
        onClick ($event) {
            this.$emit('click', $event)
        }
    }
}
</script>
