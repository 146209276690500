import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { DTOClass } from '../../DTO/DTOClass'

Vue.use(Vuex)

function langToIso (lang) {
    switch (lang) {
        case 'en':
            return 'en_US'
        case 'sk':
            return 'sk_SK'
        case 'de':
            return 'de_DE'
    }
    return 'cs_CZ'
}

export default new Vuex.Store({
    state: {
        lang: 'cz',
        enums: {},
        referenceTempId: null,
        userData: {
            language: 'cs_CZ'
        }
    },
    mutations: {
        setReferenceTempId (state, tempId) {
            state.referenceTempId = tempId
        },
        clearUserData (state) {
            state.userData = {
                language: langToIso(state.lang)
            }
        },
        setEnums (state, enums) {
            state.enums = { ...enums }
        },
        setLang (state, lang) {
            state.lang = lang
            state.userData.language = langToIso(state.lang)
        },
        mergeData (state, dto) {
            if (!(dto instanceof DTOClass)) {
                throw new Error('Invalid argument for mergeData mutation - dto must be instance of DTOClass')
            }
            const plainDto = {}
            plainDto[dto.namespace] = dto.toObject()
            state.userData = { ...JSON.parse(JSON.stringify(state.userData)), ...plainDto }
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [
        createPersistedState({
            key: 'aspenaQuestionaireApp',
            storage: window.sessionStorage,
            overwrite: true
        })
    ]
})
