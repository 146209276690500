import { ApiModuleBase } from '../ApiModuleBase.js'

export class Uploads extends ApiModuleBase {
    static get namespace () {
        return 'uploads'
    }

    static get urlNamespace () {
        return 'uploads'
    }

    get uploadUrl () {
        return `${process.env.VUE_APP_API_BASE || ''}/${this.ns}`
    }

    updateUploadType (uploadId, type) {
        return this.doPatchRequest(`/${this.ns}/${uploadId}`, { type })
    }
}
