export class DTOClass {
    static get namespace () {
        return 'dto'
    }

    get namespace () {
        return 'dto'
    }

    toObject () {
        return {}
    }

    static fromObject (dtoObject) {
        return new DTOClass()
    }
}
