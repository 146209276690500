<template>
    <div id="app" class="main-flex">
        <header class="main-header shadow-sm">
            <div class="container px-0 position-relative">
                <ul class="navbar-addon">
                </ul>
            </div>
            <div class="container px-0">
                <nav class="navbar navbar-aspena navbar-expand-lg position-static">
                    <a href="/" class="navbar-brand" title="ASPENA" target="_self">
                        <img src="https://www.aspena.cz/img/logo.svg" alt="ASPENA" class="navbar-brand-logo">
                    </a>

                    <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navComponent-mainNavigation" aria-controls="navComponent-mainNavigation" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-ham-wrapper">
                            <svg class="navbar-ham navbar-ham-rotate" viewBox="0 0 100 100">
                                <path class="ham-line ham-line-top" d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path>
                                <path class="ham-line ham-line-middle" d="m 30,50 h 40"></path>
                                <path class="ham-line ham-line-bottom" d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path>
                            </svg>
                        </span>
                        <span class="navbar-toggler-text">Menu</span>
                    </button>

                    <div class="collapse navbar-collapse" id="navComponent-mainNavigation">
                        <ul class="navbar-nav ml-auto">
                        </ul>
                    </div>
                </nav>
            </div>
        </header>

        <div class="main-content pt-5">
            <transition
                enter-active-class="animated fadeIn faster"
                leave-active-class="animated fadeOut faster"
                mode="out-in"
            >
                <router-view>
                </router-view>
            </transition>
        </div>

        <footer class="main-footer pt-5 pb-2">
            <div class="container">
                <div class="footer-logotype-wrapper">
                        <p>
                            <img src="/img/be-understood-logotype.svg" alt="Be Understood" class="img img-fluid footer-logotype">
                        </p>
                </div>
                <div class="row text-center mt-4 justify-content-center align-items-center">
                        <div class="col-12 col-lg-3 mb-2">
                            Copyright © 2021 Aspena, s.r.o.
                        </div>
                        <div class="col-12 col-lg-6 mb-2">
                        </div>
                        <div class="col-12 col-lg-3">
                            <a href="https://www.facebook.com/AspenaTranslationLocalizationInterpreting/" target="_blank" rel="noopener" title="Facebook" class="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="vertical-align: -0.125em;-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" class="iconify" data-icon="mdi:facebook"><path d="M12 2.04c-5.5 0-10 4.49-10 10.02c0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89c1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02z" fill="currentColor"></path></svg>
                            </a>
                            <a href="https://twitter.com/Aspena_CZ" target="_blank" rel="noopener" title="Twitter" class="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="vertical-align: -0.125em;-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" class="iconify" data-icon="mdi:twitter"><path d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23z" fill="currentColor"></path></svg>
                            </a>
                            <a href="https://cz.linkedin.com/company/aspena-s-r-o-" target="_blank" rel="noopener" title="LinkedIn" class="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="vertical-align: -0.125em;-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" class="iconify" data-icon="mdi:linkedin"><path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z" fill="currentColor"></path></svg>
                            </a>
                            <a href="https://www.instagram.com/aspena.languages/" target="_blank" rel="noopener" title="Instagram" class="social-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="vertical-align: -0.125em;-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" class="iconify" data-icon="mdi:instagram"><path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3z" fill="currentColor"></path></svg>
                            </a>
                        </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'App',
    data () {
        return {
            enumsLoading: true
        }
    },
    computed: {
        registrationTypeTitle () {
            const type = this.$store.state.userData.basicData.type
            return (type) ? ((type === 'company') ? this.$t('companyRegistration') : this.$t('personRegistration')) : null
        }
    },
    mounted () {
        this.loadEnums()
    },
    methods: {
        loadEnums () {
            this.enumsLoading = true
            this.$api.enums.getEnums(this.$store.state.lang)
                .then((enums) => {
                    this.$store.commit('setEnums', enums)
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('errors.cannotLoadEnums'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.enumsLoading = false
                    })
                })
        }
    }
}
</script>

<style lang="scss">
@import './scss/app.scss';

html {
    scroll-behavior: smooth;
}

.form-sm .custom-select {
    height: 2.5rem;
    padding: .5rem;
    border-radius: 0;
}

.form-sm {
    background-color: #f4f4f4;
    padding: 2rem;
    @include media-breakpoint-down('md') {
        padding: .5rem
    }
}

</style>
