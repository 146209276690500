import { ApiModuleBase } from '../ApiModuleBase.js'

export class Submissions extends ApiModuleBase {
    static get namespace () {
        return 'submissions'
    }

    static get urlNamespace () {
        return 'submissions'
    }
}
