import Router from 'vue-router'
import ComponentLoader from '../../Components/ComponentLoader.vue'
import ComponentError from '../../Components/ComponentError.vue'
// import { HeadRequestGuard } from './HeadRequestGuard.js'
const loaderSettings = {
    loading: ComponentLoader,
    error: ComponentError,
    delay: 10,
    timeout: 30 * 1000
}

const createRouter = (appGuard = null) => {
    return new Router({
        mode: 'history',
        base: '/app',
        routes: [
            {
                path: '',
                name: 'Index',
                component: () => import(/* webpackChunkName: "index" */ '../../Views/Index.vue'),
                ...loaderSettings,
                props: true,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            },
            {
                path: '/swagger',
                name: 'Api',
                component: () => import(/* webpackChunkName: "api" */ '../../Views/Api/ApiPage.vue'),
                ...loaderSettings,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            },
            {
                path: '/type-selection',
                name: 'typeSelection',
                component: () => import(/* webpackChunkName: "typeSelection" */ '../../Views/TypeSelectionPage/TypeSelectionPage.vue'),
                ...loaderSettings,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            },
            {
                path: '/basic-data/:type',
                name: 'basicData',
                component: () => import(/* webpackChunkName: "basicData" */ '../../Views/BasicDataPage/BasicDataPage.vue'),
                ...loaderSettings,
                props: true,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            },
            {
                path: '/contact-person',
                name: 'contactPerson',
                component: () => import(/* webpackChunkName: "contactPerson" */ '../../Views/ContactPersonPage/ContactPersonPage.vue'),
                ...loaderSettings,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            },
            {
                path: '/address',
                name: 'headquartersAddress',
                component: () => import(/* webpackChunkName: "headquartersAddress" */ '../../Views/HeadquartersAddressPage/HeadquartersAddressPage.vue'),
                ...loaderSettings,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            },
            {
                path: '/services',
                name: 'services',
                component: () => import(/* webpackChunkName: "services" */ '../../Views/ServicesPage/ServicesPage.vue'),
                ...loaderSettings,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            },
            {
                path: '/properties',
                name: 'propertyLists',
                component: () => import(/* webpackChunkName: "propertyLists" */ '../../Views/PropertiesListsPage/PropertiesListsPage.vue'),
                ...loaderSettings,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            },
            {
                path: '/references',
                name: 'references',
                component: () => import(/* webpackChunkName: "references" */ '../../Views/ReferencesPage/ReferencesPage.vue'),
                ...loaderSettings,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            },
            {
                path: '/work-hours',
                name: 'workHours',
                component: () => import(/* webpackChunkName: "workHours" */ '../../Views/WorkHoursPage/WorkHoursPage.vue'),
                ...loaderSettings,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            },
            {
                path: '/documents',
                name: 'documents',
                component: () => import(/* webpackChunkName: "documents" */ '../../Views/DocumentsPage/DocumentsPage.vue'),
                ...loaderSettings,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            },
            {
                path: '/purchase-conditions',
                name: 'vop',
                component: () => import(/* webpackChunkName: "vop" */ '../../Views/VopPage/VopPage.vue'),
                ...loaderSettings,
                beforeEnter: (appGuard) ? appGuard.beforeEnter() : undefined
            }
            /* {
                path: '/provisions',
                name: 'ProvisionsPage',
                component: () => import(/* webpackChunkName: "provisions" *//* '../../Views/ProvisionsPage/ProvisionsPage.vue'),
            /*    ...loaderSettings,
                beforeEnter: appGuard.beforeEnter(),
                children: [
                    {
                        path: '',
                        name: 'ProvisionsSummaryPage',
                        props (route) {
                            return { ...route.params, ...route.query }
                        },
                        component () {
                            return {
                                component: import(/* webpackChunkName: "provisionsSummary" */ /* '../../Views/ProvisionsPage/Views/Summary/SummaryPage.vue'),
            /*                    ...loaderSettings
                            }
                        }
                    }, */
        ],
        beforeEach (to, from, next) {
            window.scrollTo(0, 0)
        }
    })
}

export class RouterFactory {
    constructor (appSettings) {
        this.appSettings = appSettings
        // this.appGuard = new HeadRequestGuard(this.appSettings.APP_GUARD_URL, { url: '/' })
        this._router = createRouter()
    }

    get router () {
        return this._router
    }
}
